/* React BigCalendar */
.rbc-event {
    background-color: #ce1b28;
}
.rbc-event.rbc-selected {
    background-color: #ce1b28;
}
.rbc-row-content {
    z-index: 0;
}
.rbc-current-time-indicator {
    background-color: #ce1b28;
}
.rbc-event.event-holiday{
    background-color: #6c757d;
}
.rbc-btn-group {
    margin-bottom: 10px;
}
.rbc-toolbar-label {
    margin-bottom: 10px;
    min-width: 320px;
}

/* File Upload */
.file-upload {
    padding: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    border: 1px #ced4da dashed;
    border-radius: 0.25rem;
    cursor: pointer;
}
.file-upload-drag {
    border: 1px #ce1b28 solid;
    background-color: #ced4da;
}
.leaflet-container {
    height: 480px;
    width: 100%;
}
